@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  padding: 0;
}

body,
h3,
h4,
h5,
h6,
p,
span,
div {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.root {
  padding: 0;
}

@font-face {
  font-family: "Jubilat-Medium";
  src: local("Jubilat-Medium"), url(/static/media/Jubilat-Medium.ae0ae351.woff) format("woff");
}

@font-face {
  font-family: "Jubilat-Regular";
  src: local("Jubilat-Regular"), url(/static/media/Jubilat-Regular.b5753b9f.woff) format("woff");
}

